<template>
    <div>
        <h1>Live</h1>
        <h2>Kommende Shows</h2>
        <div class="paragraph-friso" v-if="liveShowsFuture.length === 0">
            <p>Bald werden die neuen Daten veröffentlicht</p>
        </div>

        <div class="paragraph-friso live-show-line" v-for="(liveShow) in liveShowsFuture" :key="liveShow.title">
            <div class="live-date">
                <p>{{ ('0' + liveShow.date.getDate()).slice(-2) }}. {{ ('0' + (liveShow.date.getMonth() + 1)).slice(-2)
                    }}. {{
                        liveShow.date.getYear() - 100
                    }}</p>
            </div>
            <div class="live-description">
                <p>{{ liveShow.title }}</p>
            </div>
            <div v-if="liveShow.link && liveShow.link != ''" class="live-btn" @click="eventClicked(liveShow.link)">
                <p>mehr</p><!-- <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                    src="@/assets/graphics/buttons/mehr_mobile.svg" />
                <img v-else class="social-button desktop-button btn-friso"
                    src="@/assets/graphics/buttons/mehr_desktop.svg" /> -->
            </div>
        </div>
        <h2>Vergangene Shows</h2>
        <div class="paragraph-friso live-show-line" v-for="(liveShow) in liveShowsPast" :key="liveShow.title">
            <div class="live-date">
                <p>{{ ('0' + liveShow.date.getDate()).slice(-2) }}. {{ ('0' + (liveShow.date.getMonth() + 1)).slice(-2)
                    }}. {{
                        liveShow.date.getYear() - 100
                    }}</p>
            </div>
            <div class="live-description">
                <p>{{ liveShow.title }}</p>
            </div>
            <div v-if="false" class="live-btn" @click="eventClicked(liveShow.link)">
                <p>mehr</p><!-- <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                    src="@/assets/graphics/buttons/mehr_mobile.svg" />
                <img v-else class="social-button desktop-button btn-friso"
                    src="@/assets/graphics/buttons/mehr_desktop.svg" /> -->
            </div>
        </div>
    </div>
</template>

<script>
function sortByKey(array, key, ascending = true) {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (ascending) {
            if (x < y) return -1;
            if (x > y) return 1;
        } else {
            if (x > y) return -1; // Descending order
            if (x < y) return 1; // Descending order
        }
        return 0;
    });
}
import {
    liveShows
} from "../data/data.js";
// "https://www.dropbox.com/sh/fffppft24a66v4t/AADGyJuCQIWvxT3k5ELls6zXa/data.js"
export default {
    name: 'KontaktForm',
    components: {},
    data() {
        const today = new Date();
        console.log("today: ", today, today)
        const isSameDate = (date1, date2) => {
            return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate();
        };
        return {
            liveShowsFuture: sortByKey(
                liveShows.filter(show => {
                    const showDate = new Date(show.date);
                    return showDate >= today || isSameDate(showDate, today); // Include today's shows
                }),
                "date"
            ),
            liveShowsPast: sortByKey(
                liveShows.filter(show => {
                    const showDate = new Date(show.date);
                    return showDate < today && !isSameDate(showDate, today); // Exclude today's shows
                }),
                "date",
                false
            ),
            innerWidth: window.innerWidth,
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        eventClicked(url) {
            window.open(url, '_blank');
        },
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        },

    }
}
</script>

<style scoped>
.live-show-line {
    width: min(80vw, 1000px);
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
}


.live-date {
    text-align: left;
    width: calc(0.2*min(80vw, 1000px));
    padding-right: 2px;
}

.live-btn {
    display: contents;
    color: var(--achterbahn-color);
    cursor: pointer;
    width: calc(0.2*min(80vw, 1000px))
}

.live-description {
    text-align: left;
    width: calc(0.6*min(80vw, 1000px));
    padding-left: 10px;
    padding-right: 10px;
}

h2 {
    color: white;
    font-style: italic;
}
</style>
